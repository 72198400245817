import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { format, subDays, addDays } from 'date-fns';
import axios from 'axios';
import ExcelJS from 'exceljs';
import './DailyReportsPage.css'; // Подключаем стили
import Toast from './Toast';
import { getAllFactories, getDailyReports } from '../api';  // Импортируем функции из api.js


const DailyReportsPage = () => {
    const [factories, setFactories] = useState([]);
    const [selectedFactories, setSelectedFactories] = useState([]);
    //const [date, setDate] = useState(format(subDays(new Date(), 1), 'yyyy-MM-dd')); // инициализированная дата
    const [date, setDate] = useState(format(subDays(new Date(), 0), 'yyyy-MM-dd')); // инициализированная дата
    const [reports, setReports] = useState([]);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success или error

    // Получаем заводы при загрузке страницы
       useEffect(() => {
           getAllFactories()
               .then(response => setFactories(response.data))
               .catch(error => {
                   setToastMessage('Ошибка при загрузке заводов: ' + error.message);
                   setToastType('error');
               });
       }, []);

    // Получаем отчеты при изменении выбранных заводов или даты
        useEffect(() => {
            const factoryIds = selectedFactories.map(factory => factory.value);

                getDailyReports(factoryIds, date)
                    .then(response => setReports(response.data))
                    .catch(error => {
                        setToastMessage('Ошибка при загрузке отчетов: ' + error.message);
                        setToastType('error');
                        console.error('Ошибка при загрузке отчетов:', error);
                    });

        }, [selectedFactories, date]);

    const factoryOptions = factories.map(factory => ({
        value: factory.id,
        label: factory.name
    }));

    const decrementDate = () => setDate(format(subDays(new Date(date), 1), 'yyyy-MM-dd'));
    const incrementDate = () => setDate(format(addDays(new Date(date), 1), 'yyyy-MM-dd'));

    const calculateTotals = () => {
        const totals = { atStationToday: 0, totalInCity: 0, newWorkersToday: 0, onMedicalToday: 0, leftFactoryToday: 0, onVacationToday: 0, requestToday: 0 };
        reports.forEach(report => {
            totals.atStationToday += report.atStationToday;
            totals.totalInCity += report.totalInCity;
            totals.newWorkersToday += report.newWorkersToday;
            totals.onMedicalToday += report.onMedicalToday;
            totals.leftFactoryToday += report.leftFactoryToday;
            totals.onVacationToday += report.onVacationToday;
            totals.requestToday += report.requestToday;
        });
        return totals;
    };

    const totals = calculateTotals();

    /**
     * Универсальная функция для установки высоты строки на основе содержимого всех ячеек
     * @param {ExcelJS.Row} row - Объект строки ExcelJS
     * @param {number} baseHeight - Базовая высота строки (по умолчанию 15 пикселей на одну строку)
     */
    const adjustRowHeight = (row, startColumn = 1, baseHeight = 15) => {
        // Функция для подсчета количества строк в ячейке
        const getLineCount = (text) => {
            if (!text) return 1;
            return text.toString().split(/\r\n|\r|\n/).length; // Преобразуем текст в строку и разбиваем по переносам строк
        };

        // Перебираем все ячейки в строке и находим максимальное количество строк
        let maxLines = 1; // Минимум одна строка
        row.eachCell((cell) => {
            if (cell.col < startColumn) return;
            const lines = getLineCount(cell.value);
            if (lines > maxLines) {
                maxLines = lines;
            }
        });

        // Устанавливаем высоту строки в зависимости от максимального количества строк
        row.height = maxLines * baseHeight;
    };

    // Функция для задания шрифта для прямоугольной области
    const setFontForRange = (worksheet, startCell, endCell, fontStyle) => {
        const startCellRef = worksheet.getCell(startCell);  // Начальная ячейка (например, B2)
        const endCellRef = worksheet.getCell(endCell);  // Конечная ячейка (например, D5)

        // Перебираем все строки и столбцы в указанной области
        for (let rowNum = startCellRef.row; rowNum <= endCellRef.row; rowNum++) {
            for (let colNum = startCellRef.col; colNum <= endCellRef.col; colNum++) {
                const cell = worksheet.getCell(rowNum, colNum);
                // Применяем стиль шрифта к каждой ячейке в диапазоне
                cell.font = fontStyle;
            }
        }
    };


    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(format(date, 'dd.MM.yyyy'), {views:[{xSplit: 1, ySplit:1}]});

        // Установим ширину для колонок
        worksheet.columns = [
            { header: '№', key: 'idx', width: 5, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'Наименование предприятия', key: 'factoryName', width: 30, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: '01 число', key: 'day01', width: 15, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'ПЛАН', key: 'plan', width: 15, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'Работает у станка', key: 'atStationToday', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'Всего в городе', key: 'totalInCity', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'выход на завод', key: 'newWorkersToday', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'оформление, МО и прочее', key: 'onMedicalToday', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'уехали', key: 'leftFactoryToday', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'НА м/вахтовом отпуске', key: 'onVacationToday', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'заявка', key: 'requestToday', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'Заявка', key: 'request', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'На отработке', key: 'onWork', width: 10, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'Данные', key: 'data', width: 30, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
            { header: 'ТИП', key: 'type', width: 25, alignment: { vertical: 'middle', horizontal: 'center' }, font: {bold: true}, wrapText: true },
        ];

        // Заполним данные отчета
        reports.forEach((report, idx) => {
            const row = worksheet.addRow({
                idx: idx + 1,
                factoryName: `${report.factory.name}\n${report.master?.fio}\n${report.master?.phone}`,
                day01: '',
                plan: '',
                atStationToday: report.atStationToday,
                totalInCity: report.totalInCity,
                newWorkersToday: report.newWorkersToday,
                onMedicalToday: report.onMedicalToday,
                leftFactoryToday: report.leftFactoryToday,
                onVacationToday: report.onVacationToday,
                requestToday: report.requestToday,
                request: '',
                onWork: '',
                data: report.whoOnApproval,
                type: 'Кто на согласовании'
            });
            // Применяем жирный шрифт только для части текста в ячейке factoryName
            row.getCell('factoryName').value = {
                richText: [
                    { text: report.factory.name, font: { bold: true, size: 16 } }, // Жирный текст для имени завода
                    { text: `\n${report.master?.fio}\n${report.master?.phone}`, font: {size: 12} } // Остальная часть текста обычная
                ]
            };
            row.alignment = { vertical: 'middle', horizontal: 'center' }
            adjustRowHeight(row, 3);

            worksheet.addRow({
                idx: '',
                factoryName: '',
                day01: '',
                plan: '',
                atStationToday: '',
                totalInCity: '',
                newWorkersToday: '',
                onMedicalToday: '',
                leftFactoryToday: '',
                onVacationToday: '',
                requestToday: '',
                request: '',
                onWork: '',
                data: report.whoInCity,
                type: 'Кто в городе'
            });
            row.alignment = { vertical: 'middle', horizontal: 'center' };
            adjustRowHeight(row, 3);

            worksheet.addRow({
                idx: '',
                factoryName: '',
                day01: '',
                plan: '',
                atStationToday: '',
                totalInCity: '',
                newWorkersToday: '',
                onMedicalToday: '',
                leftFactoryToday: '',
                onVacationToday: '',
                requestToday: '',
                request: '',
                onWork: '',
                data: report.whoAtFactory,
                type: 'Кто вышел на завод'
            });
            row.alignment = { vertical: 'middle', horizontal: 'center' }
            adjustRowHeight(row, 3);

            worksheet.addRow({
                idx: '',
                factoryName: '',
                day01: '',
                plan: '',
                atStationToday: '',
                totalInCity: '',
                newWorkersToday: '',
                onMedicalToday: '',
                leftFactoryToday: '',
                onVacationToday: '',
                requestToday: '',
                request: '',
                onWork: '',
                data: report.whoOnSickLeave,
                type: 'Кто на больничном/отгуле'
            });
            row.alignment = { vertical: 'middle', horizontal: 'center' }
            adjustRowHeight(row, 3);

            worksheet.addRow({
                idx: '',
                factoryName: '',
                day01: '',
                plan: '',
                atStationToday: '',
                totalInCity: '',
                newWorkersToday: '',
                onMedicalToday: '',
                leftFactoryToday: '',
                onVacationToday: '',
                requestToday: '',
                request: '',
                onWork: '',
                data: report.whoReturnedFromVacation,
                type: 'Кто вернулся с межвахты'
            });
            row.alignment = { vertical: 'middle', horizontal: 'center' }
            adjustRowHeight(row, 3);

            worksheet.addRow({
                idx: '',
                factoryName: '',
                day01: '',
                plan: '',
                atStationToday: '',
                totalInCity: '',
                newWorkersToday: '',
                onMedicalToday: '',
                leftFactoryToday: '',
                onVacationToday: '',
                requestToday: '',
                request: '',
                onWork: '',
                data: report.whoLeftForVacation,
                type: 'Кто убыл в межвахту'
            });
            row.alignment = { vertical: 'middle', horizontal: 'center' }
            adjustRowHeight(row, 3);

            worksheet.addRow({
                idx: '',
                factoryName: '',
                day01: '',
                plan: '',
                atStationToday: '',
                totalInCity: '',
                newWorkersToday: '',
                onMedicalToday: '',
                leftFactoryToday: '',
                onVacationToday: '',
                requestToday: '',
                request: '',
                onWork: '',
                data: report.whoFiredToday,
                type: 'Кто уволен'
            });
            row.alignment = { vertical: 'middle', horizontal: 'center' }
            adjustRowHeight(row, 3);

            worksheet.mergeCells(worksheet.rowCount-6, 1, worksheet.rowCount, 1);
            worksheet.mergeCells(worksheet.rowCount-6, 2, worksheet.rowCount, 2);

            worksheet.mergeCells(worksheet.rowCount-6, 3, worksheet.rowCount-4, 3);
            worksheet.mergeCells(worksheet.rowCount-3, 3, worksheet.rowCount, 3);

            worksheet.mergeCells(worksheet.rowCount-6, 4, worksheet.rowCount-4, 4);
            worksheet.mergeCells(worksheet.rowCount-3, 4, worksheet.rowCount, 4);

            worksheet.mergeCells(worksheet.rowCount-6, 5, worksheet.rowCount, 5);
            worksheet.mergeCells(worksheet.rowCount-6, 6, worksheet.rowCount, 6);
            worksheet.mergeCells(worksheet.rowCount-6, 7, worksheet.rowCount, 7);
            worksheet.mergeCells(worksheet.rowCount-6, 8, worksheet.rowCount, 8);
            worksheet.mergeCells(worksheet.rowCount-6, 9, worksheet.rowCount, 9);
            worksheet.mergeCells(worksheet.rowCount-6, 10, worksheet.rowCount, 10);
            worksheet.mergeCells(worksheet.rowCount-6, 11, worksheet.rowCount, 11);
            worksheet.mergeCells(worksheet.rowCount-6, 12, worksheet.rowCount, 12);
            worksheet.mergeCells(worksheet.rowCount-6, 13, worksheet.rowCount, 13);

    });

    // Определяем стиль фона для заливки (желтый цвет)
        const fillStyle = (color) => { return {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color }, // Жёлтый цвет в формате ARGB
        }};


        // Установим высоту для первой строки (опционально)
        worksheet.getRow(1).height = 50;
        worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.getRow(1).font = { size: 10 }

        worksheet.getColumn(3).eachCell((cell) => {
            cell.fill = fillStyle('808080');
            cell.border = {
                top: {style:'thin', color: { argb: 'FF999999' }},
                left: {style:'thin', color: { argb: 'FF999999' }},
                bottom: {style:'thin', color: { argb: 'FF999999' }},
                right: {style:'thin', color: { argb: 'FF999999' }}
            }

        });
        worksheet.getColumn(4).eachCell((cell) => {
            cell.fill = fillStyle('FFFF00');
            cell.border = {
                top: {style:'thin', color: { argb: 'FF999999' }},
                left: {style:'thin', color: { argb: 'FF999999' }},
                bottom: {style:'thin', color: { argb: 'FF999999' }},
                right: {style:'thin', color: { argb: 'FF999999' }}
            }

        });

        worksheet.getColumn(14).alignment = { vertical: 'middle', horizontal: 'right' }
        worksheet.getColumn(15).alignment = { vertical: 'middle', horizontal: 'left' }

        // Применяем выравнивание и перенос текста ко всем ячейкам данных
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.alignment = {...cell.alignment, wrapText: true };
            });
        });

        setFontForRange(worksheet, 'C2', "K" + worksheet.rowCount, {size: 14});

        // Записываем Excel файл
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Дневной_отчет_${date}.xlsx`;
        link.click();
    };



    const formatTextWithLineBreaks = (text) => {
        return text?.trim().split(/\r\n|\n|\r/)?.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="daily-reports__page-container">

            <div className="daily-reports__filter-container">
                <div className="filter-group">
                    <Select
                        id="factories"
                        options={factoryOptions}
                        isMulti
                        onChange={setSelectedFactories}
                        placeholder="Выберите заводы"
                        className="factory-select"
                    />
                </div>
                <div className="date-controls">
                    <button className="date-button" onClick={decrementDate}>{"◀"}</button>
                    <input
                        id="date"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="date-input"
                    />
                    <button className="date-button" onClick={incrementDate}>{"▶"}</button>
                </div>
                <div className="export-controls">
                    <button className="export-button" onClick={exportToExcel}>Выгрузить в Excel</button>
                </div>
            </div>

            <div className="daily-reports__report-table">
                <table className="reports-table">
                    <thead>
                        <tr>
                            <th className="td_idx"></th>
                            <th>Наименование предприятия</th>
                            <th className="td_num">Сколько<br/>сегодня у<br/>станка?</th>
                            <th className="td_num">Сколько<br/>всего в<br/>городе?</th>
                            <th className="td_num">Новые<br/>сотруд-<br/>ники</th>
                            <th className="td_num">На офрмл<br/>м/о</th>
                            <th className="td_num">Уволено<br/>сегодня</th>
                            <th className="td_num">На м/вх<br/>отпуске</th>
                            <th className="td_num">Заявка<br/>сегодня</th>
                            <th className="td_table">ТИП</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((report, idx) => (
                            <tr key={report.id}>
                                <td className="td_idx">{idx+1}</td>
                                <td className="td_factory">
                                    <div className="td_factory_name">{report.factory.name}</div>
                                    <div className="td_master_fio">Мастер - {report.master?.fio}</div>
                                    <div className="td_master_phone">{report.master?.phone}</div>
                                </td>
                                <td className="td_num">{report.atStationToday}</td>
                                <td className="td_num">{report.totalInCity}</td>
                                <td className="td_num">{report.newWorkersToday}</td>
                                <td className="td_num">{report.onMedicalToday}</td>
                                <td className="td_num">{report.leftFactoryToday}</td>
                                <td className="td_num">{report.onVacationToday}</td>
                                <td className="td_num">{report.requestToday}</td>

                                <td className="td_table">
                                    <table className="td_table">
                                    <tbody>
                                    <tr>
                                        <td>{formatTextWithLineBreaks(report.whoOnApproval)}</td>
                                        <th>согласование</th>
                                    </tr>

                                    <tr>
                                        <td>{formatTextWithLineBreaks(report.whoInCity)}</td>
                                        <th>в городе</th>
                                    </tr>

                                    <tr>
                                        <td>{report.whoAtFactory}</td>
                                        <th>выход</th>
                                    </tr>

                                    <tr>
                                        <td>{report.whoOnSickLeave}</td>
                                        <th>больн/отгул</th>
                                    </tr>

                                    <tr>
                                        <td>{report.whoReturnedFromVacation}</td>
                                        <th>возврат м/в</th>
                                    </tr>

                                    <tr>
                                        <td>{report.whoLeftForVacation}</td>
                                        <th>убыл в м/а</th>
                                    </tr>

                                    <tr>
                                        <td>{report.whoFiredToday}</td>
                                        <th>уволен</th>
                                    </tr>
                                    </tbody>
                                    </table>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        {reports.length > 1 && (
                            <tr className="daily-reports__totals-row">
                                <td/>
                                <td><strong>Итог</strong></td>
                                <td className="td_num">{totals.atStationToday}</td>
                                <td className="td_num">{totals.totalInCity}</td>
                                <td className="td_num">{totals.newWorkersToday}</td>
                                <td className="td_num">{totals.onMedicalToday}</td>
                                <td className="td_num">{totals.leftFactoryToday}</td>
                                <td className="td_num">{totals.onVacationToday}</td>
                                <td className="td_num">{totals.requestToday}</td>
                                <td/>
                            </tr>
                        )}
                    </tfoot>
                </table>
            </div>

            {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setToastMessage(null)}
                />
            )}
        </div>
    );
};

export default DailyReportsPage;
