import React, { useState, useEffect, useRef } from 'react';
import { getMasterByLink, getFactoriesForMaster, createReport, getReportToday } from '../../api';
import { useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './MasterReportForm.css';

const MasterReportForm = () => {
    const { uniqueLink } = useParams();
    const [master, setMaster] = useState(null);
    const [factories, setFactories] = useState([]);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditable, setIsEditable] = useState(false);
    const [hasId, setHasId] = useState(false);
    const [report, setReport] = useState({
        atStationToday: 0,
        totalInCity: 0,
        newWorkersToday: 0,
        onMedicalToday: 0,
        leftFactoryToday: 0,
        onVacationToday: 0,
        requestToday: '',
        whoOnApproval: '',
        whoInCity: '',
        whoAtFactory: '',
        whoOnSickLeave: '',
        whoReturnedFromVacation: '',
        whoLeftForVacation: '',
        whoFiredToday: ''
    });
    const [initialReport, setInitialReport] = useState(null); // Для хранения изначальных данных отчета

    const currentDate = new Date().toISOString().split('T')[0];
    const currentDateTitle = new Date().toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    const currentDateTitleWeekday = new Date().toLocaleDateString('ru-RU', { weekday: 'long' });


    const textAreasRefs = useRef([]);

    useEffect(() => {
        setLoading(true);
        getMasterByLink(uniqueLink)
            .then(master => {
                setMaster(master);
                return getFactoriesForMaster(master.id);
            })
            .then(factoriesResponse => {
                setFactories(factoriesResponse.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching master or factories data', error);
                setLoading(false);
            });
    }, [uniqueLink]);

    useEffect(() => {
        if (factories.length === 1) {
            setSelectedFactory(factories[0]);
        }
    }, [factories]);

    useEffect(() => {
        if (selectedFactory && master) {
            getReportToday(selectedFactory.id)
                .then(response => {
                    if (response.data) {
                        setReport(response.data);
                        setInitialReport(response.data);
                        // Если отчет заполнен не нулями, ставим режим просмотра
                        if (response.data.id) {
                            setHasId(true);
                            setIsEditable(false); // Режим просмотра
                        } else {
                            setHasId(false);
                            setIsEditable(true); // Режим редактирования
                        }
                    } else {
                        setIsEditable(true); // Режим редактирования
                    }
                })
                .catch(error => {
                    console.error('Error fetching report data', error);
                });
        }
    }, [selectedFactory, master]);

    const handleFactorySelect = (factory) => {
        setSelectedFactory(factory);
    };

    const handleBackToList = () => {
        setSelectedFactory(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createReport({ ...report, master: master, factory: selectedFactory, reportDate: currentDate })
            .then(() => {
                alert('Отчет успешно отправлен!');
                setIsEditable(false); // Переключаем в режим просмотра
            })
            .catch(error => {
                console.error('Error submitting report', error);
            });
    };

    const handleCancel = () => {
        setReport(initialReport); // Возвращаем исходное состояние отчета
        setIsEditable(false); // Выключаем режим редактирования
    };

    const handleEdit = () => {
        setIsEditable(true); // Включаем режим редактирования
    };

    const handleTextareaChange = (e, field) => {
        const text = e.target.value;
        setReport({ ...report, [field]: text });
        autoResizeTextarea(e.target);
    };

    const autoResizeTextarea = (textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.max(textarea.scrollHeight, 3 * 24)}px`;
    };

    const handleFocus = (e) => {
        e.target.select(); // Выделение текста при фокусе
    };

    if (loading) {
        return <div>Загрузка данных...</div>;
    }

    if (!master) {
        return <div>Мастер не найден.</div>;
    }

    if (factories.length === 0) {
        return <div>Отсутствуют закрепленные заводы.</div>;
    }

    if (!selectedFactory && factories.length > 1) {
        return (
            <div className="factory-select-container">
                <ul className="factory-list">
                    {factories.map(factory => (
                        <li key={factory.id}>
                            <button onClick={() => handleFactorySelect(factory)} className="factory-select-button">
                                {factory.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    return (
        <div className="report-form-container">
            <header className="report-header">
                {factories.length > 1 && (
                    <button onClick={handleBackToList} className="back-icon">
                        <FaArrowLeft size={20} />
                    </button>
                )}
                <h2>{selectedFactory?.name} | {currentDateTitle} ({currentDateTitleWeekday})</h2>
                {!isEditable && (
                    <button onClick={handleEdit} className="edit-button">Редактировать</button>
                )}
            </header>

            <form onSubmit={handleSubmit} className="report-form">
                <div className="form-group">
                    <label>Сколько сегодня у станка?</label>
                    <input
                        type="number"
                        value={report.atStationToday}
                        onFocus={handleFocus}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, atStationToday: e.target.value })}
                        disabled={!isEditable} // Заблокировано, если не в режиме редактирования
                    />
                </div>
                <div className="form-group">
                    <label>Сколько всего в городе?</label>
                    <input
                        type="number"
                        onFocus={handleFocus}
                        value={report.totalInCity}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, totalInCity: e.target.value })}
                    />
                </div>
                <div className="form-group">
                    <label>Сколько новых сегодня вышло на завод?</label>
                    <input
                        type="number"
                        onFocus={handleFocus}
                        value={report.newWorkersToday}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, newWorkersToday: e.target.value })}
                    />
                </div>
                <div  className="form-group">
                    <label>Сколько сегодня человек на оформлении/медосмотре?</label>
                    <input
                        type="number"
                        onFocus={handleFocus}
                        value={report.onMedicalToday}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, onMedicalToday: e.target.value })}
                    />
                </div>
                <div className="form-group">
                    <label>Сколько сегодня человек уехало с завода?</label>
                    <input
                        type="number"
                        onFocus={handleFocus}
                        value={report.leftFactoryToday}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, leftFactoryToday: e.target.value })}
                    />
                </div>
                <div className="form-group">
                    <label>Сколько сегодня на межвахтовом отпуске?</label>
                    <input
                        type="number"
                        onFocus={handleFocus}
                        value={report.onVacationToday}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, onVacationToday: e.target.value })}
                    />
                </div>

                <div>
                    <label>Какая заявка на сегодня?</label>
                    <input
                        type="number"
                        onFocus={handleFocus}
                        value={report.requestToday}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, requestToday: e.target.value })}
                    />
                </div>
                <div>
                    <label>Кто на согласовании и дата приезда?</label>
                    <textarea
                        rows="2"
                        value={report.whoOnApproval}
                        ref={el => textAreasRefs.current[0] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoOnApproval')}
                    />
                </div>
                <div>
                    <label>Кто в городе, но еще не вышел на завод?</label>
                    <textarea
                        rows="2"
                        value={report.whoInCity}
                        ref={el => textAreasRefs.current[1] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoInCity')}
                    />
                </div>
                <div>
                    <label>Кто вышел сегодня на завод? (ФИО новеньких)</label>
                    <textarea
                        rows="2"
                        value={report.whoAtFactory}
                        ref={el => textAreasRefs.current[2] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoAtFactory')}
                    />
                </div>
                <div>
                    <label>Кто на больничном/отгуле/административном?</label>
                    <textarea
                        rows="2"
                        value={report.whoOnSickLeave}
                        ref={el => textAreasRefs.current[3] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoOnSickLeave')}

                    />
                </div>
                <div>
                    <label>Кто вернулся с межвахты сегодня?</label>
                    <textarea
                        rows="2"
                        value={report.whoReturnedFromVacation}
                        ref={el => textAreasRefs.current[4] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoReturnedFromVacation')}
                    />
                </div>
                <div>
                    <label>Кто убыл на межвахту и дата возврата?</label>
                    <textarea
                        rows="2"
                        value={report.whoLeftForVacation}
                        ref={el => textAreasRefs.current[5] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoLeftForVacation')}
                    />
                </div>

                <div>
                    <label>Кто уволен сегодня и причина?</label>
                    <textarea
                        rows="2"
                        value={report.whoFiredToday}
                        ref={el => textAreasRefs.current[6] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoFiredToday')}
                    />
                </div>

                <div className="form-buttons">
                                    {isEditable && (
                                        <>
                                            <button type="submit" className="submit-button">Сохранить и отправить</button>
                                            { hasId && (<button type="button" className="cancel-button" onClick={handleCancel}>Отмена</button>)}
                                        </>
                                    )}
                                </div>
                            </form>
                        </div>
                    );
                };

                export default MasterReportForm;