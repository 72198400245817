import React from 'react';
import './FormInput.css'; // Стили

const FormInput = ({ label, type, value, onChange, error, placeholder, className }) => (
  <div className={`${className} form-input__form-group`}>
    <label>{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`form-input__input-field ${error ? 'error-border' : ''}`}
    />
    {error && <span className="form-input__error-message">{error}</span>}
  </div>
);

export default FormInput;
