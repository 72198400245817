import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt, FaRegChartBar, FaIndustry, FaUserTie } from 'react-icons/fa'; // Импортируем иконки
import './ManagerMenu.css'; // Стили

const ManagerMenu = () => {
    return (
        <nav className="manager-menu">
            <ul>
                <li>
                    <Link to="/manager/daily-reports">
                        <FaRegCalendarAlt className="menu-icon" /> Дневные отчеты
                    </Link>
                </li>
                <li>
                    <Link to="/manager/monthly-reports">
                        <FaRegChartBar className="menu-icon" /> Табель
                    </Link>
                </li>
                <li>
                    <Link to="/manager/factories">
                        <FaIndustry className="menu-icon" /> Заводы
                    </Link>
                </li>
                <li>
                    <Link to="/manager/masters">
                        <FaUserTie className="menu-icon" /> Мастера
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default ManagerMenu;
