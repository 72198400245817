import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Используем переменную окружения
console.log('API URL:', API_URL);


// Создаем экземпляр Axios
const apiClient = axios.create({
    baseURL: API_URL,
});

// Интерсептор для установки токена перед каждым запросом
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Интерцептор для обработки ошибок
apiClient.interceptors.response.use(
    (response) => {
        return response;  // Возвращаем ответ, если запрос прошел успешно
    },
    (error) => {
        if (error.response && error.response.status === 403) {
            const token = localStorage.getItem('token');
            if (token) {
                localStorage.removeItem('token');  // Удаляем токен из localStorage
            }
            window.location.href = '/';  // Перенаправляем на главную страницу
        } else {
             // Можно добавить обработку других ошибок, если необходимо
             console.error('Ошибка сервера:', error);
        }
        return Promise.reject(error);
    }
);

// Функция для установки токена в localStorage
export const setAuthToken = (token) => {
    if (token) {
        localStorage.setItem('token', token);  // Сохраняем токен в localStorage
    } else {
        localStorage.removeItem('token');  // Удаляем токен, если передан null
    }
};

// Получить информацию о мастере по уникальной ссылке
export const getMasterByLink = async (uniqueLink) => {
        let response = await apiClient.get(`${API_URL}/masters/id/${uniqueLink}`);
        console.log(response);
        setAuthToken(response.data.token);
        console.log('ok');
        return response.data.master;
};

// Создать отчет для мастера
export const createReport = (report) => {
    return apiClient.post(`${API_URL}/reports/create`, report);
};
// Получить отчет по мастеру и заводу за текущую дату
export const getReportToday = (factoryId) => {
    return apiClient.get(`${API_URL}/reports/today?factoryId=${factoryId}`);
};


// Создать нового мастера
export const createMaster = (master) => {
    return apiClient.post(`${API_URL}/masters/create`, master);
};

// Заблокировать мастера
export const blockMaster = (masterId) => {
    return apiClient.put(`${API_URL}/masters/block/${masterId}`);
};

// Разблокировать мастера
export const unblockMaster = (masterId) => {
    return apiClient.put(`${API_URL}/masters/unblock/${masterId}`);
};

// Удалить мастера
export const deleteMaster = (masterId) => {
    return apiClient.delete(`${API_URL}/masters/delete/${masterId}`);
};

// Обновить мастера
export const updateMaster = (id, master) => {
    return apiClient.put(`${API_URL}/masters/update/${id}`, master);
};

// Проверка доступа менеджера по коду
export const checkManagerAccess = async (code) => {
    let response = await apiClient.post(`${API_URL}/manager/login`, {"code": code}, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded'  // Устанавливаем заголовок для формы
         }
    });
    console.log(response);
    setAuthToken(response.data.token);
    console.log('ok');
    return response;
}


// Получить список всех заводов, привязанных к мастеру
export const getFactoriesForMaster = (masterId) => {
    return apiClient.get(`${API_URL}/factories/master`);
};


// Обновить завод
export const updateFactory = (id, factory) => {
    return apiClient.put(`${API_URL}/factories/update/${id}`, factory);
};

// Удалить завод
export const deleteFactory = (id) => {
    return apiClient.delete(`${API_URL}/factories/delete/${id}`);
};

// Получить доступные заводы для мастера
export const getAvailableFactoriesForMaster = (masterId) => {
    return apiClient.get(`${API_URL}/masters/${masterId}/available-factories`);
};

// Получить список всех заводов
export const getAllFactories = () => {
    return apiClient.get(`${API_URL}/factories/all`);
};

// Получить список всех мастеров
export const getAllMasters = () => {
    return apiClient.get(`${API_URL}/masters/all`);
};

// Создать новый завод
export const createFactory = (factory) => {
    return apiClient.post(`${API_URL}/factories/create`, factory);
};


// Загрузка табеля (timesheet) в базу
export const uploadTimesheet = (timesheetData) => {
    return apiClient.post(`${API_URL}/timesheets/upload`, timesheetData);
};

// Получение табеля для завода и месяца
export const getTimesheetForFactoryAndMonth = (factoryId, month) => {
    return apiClient.get(`${API_URL}/timesheets`, { params: { factoryId, month } });
};

// Получить отчеты по выбранным заводам и дате
export const getDailyReports = (factoryIds, date) => {
    return apiClient.get(`${API_URL}/reports/findDailyReport`, {
        params: {
            factoryIds: factoryIds.join(','),  // Преобразуем массив в строку
            date
        }
    });
};


export const getAggregatedDailyReports = async (factoryId, month) => {
    try {
        const response = await apiClient.get('/reports/aggregated-daily-reports', {
            params: {
                factoryId,
                month
            }
        });
        return response.data;
    } catch (error) {
        console.error("Ошибка при получении агрегированных данных:", error);
        return [];
    }
};


