import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
    getAllFactories,
    uploadTimesheet,
    getTimesheetForFactoryAndMonth,
    getAggregatedDailyReports
} from '../api';
import ExcelParser from './ExcelParser';
import './MonthlyReportsPage.css';
import { format, addMonths, subMonths } from 'date-fns';
import Toast from './Toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


const MonthlyReportsPage = () => {
    const [factories, setFactories] = useState([]);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(format(new Date(), 'yyyy-MM')); // Текущий месяц по умолчанию
    const [parsingErrors, setParsingErrors] = useState([]);
    const [parsedData, setParsedData] = useState(null);
    const [savedTimesheet, setSavedTimesheet] = useState(null);
    const [displayData, setDisplayData] = useState(null); // Единое место для отображаемых данных
    const [loading, setLoading] = useState(false); // Состояние для контроля загрузки
    const [isEmployeeRowsCollapsed, setIsEmployeeRowsCollapsed] = useState(true); // Для управления сворачиванием строк сотрудников
    const [aggregatedData, setAggregatedData] = useState(null); // Для хранения агрегированных данных
    const [isModified, setIsModified] = useState(false); // Отслеживание изменений в таблице
    const [isLoadedFromDatabase, setIsLoadedFromDatabase] = useState(false); // Флаг загрузки из базы
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success или error

    // Загружаем список заводов
    useEffect(() => {
            setLoading(true);
            getAllFactories()
                .then(response => {
                    setFactories(response.data.map(factory => ({
                        value: factory.id,
                        label: factory.name
                    })));
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Ошибка при загрузке заводов:', error);
                    setLoading(false);
                    setToastMessage('Ошибка при загрузке заводов. Пожалуйста, попробуйте позже.');
                    setToastType('error');
                });
        }, []);

    useEffect(() => {
            if (selectedFactory && selectedMonth) {
                handleLoadTimesheet();
                loadAggregatedReports();
            }
        }, [selectedFactory, selectedMonth]);

    const handleFactoryChange = (factory) => {
        setSelectedFactory(factory);
    };

    const getFactoryIndex = () => {
        return factories.findIndex(f => f.value === selectedFactory?.value);
    };

    const decrementFactory = () => {
        const currentIndex = getFactoryIndex();
        if (currentIndex > 0) {
            setSelectedFactory(factories[currentIndex - 1]);
        }
    };

    const incrementFactory = () => {
        const currentIndex = getFactoryIndex();
        if (currentIndex < factories.length - 1) {
            setSelectedFactory(factories[currentIndex + 1]);
        }
    };

    // Обработка загруженного Excel файла
    const handleParsedData = (result) => {
            if (result.errors) {
                setParsingErrors(result.errors);
                setDisplayData(null);
                setToastMessage(result.errors.join('\n'));
                setToastType('error');
            } else {
                setParsedData(result.data);
                setDisplayData(result.data);
                setParsingErrors([]);
                setIsModified(true);
                setIsLoadedFromDatabase(false);
                setToastMessage('Табель успешно загружен.');
                setToastType('success');
            }
        };

    // Добавьте функцию для обработки ошибок от ExcelParser
    const handleParserError = (errorMessage) => {
                setParsingErrors([errorMessage]);
                setDisplayData(null);
                setToastMessage(errorMessage);
                setToastType('error');
            };

    // Загрузка табеля из базы
    const handleLoadTimesheet = () => {
        if (!selectedFactory || !selectedMonth) {
                setToastMessage('Укажите завод и месяц');
                setToastType('warn');
                return;
        }

        setLoading(true); // Включаем состояние загрузки
        getTimesheetForFactoryAndMonth(selectedFactory.value, selectedMonth)
                    .then(response => {
                        if (response.data && Array.isArray(response.data.data)) {
                            setSavedTimesheet(response.data);
                            setDisplayData(response.data.data);
                            setIsLoadedFromDatabase(true);
                            setIsModified(false);
                        } else {
                            setSavedTimesheet(null);
                            setDisplayData(null);
                            setIsLoadedFromDatabase(false);
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Ошибка при загрузке табеля:", error);
                        setLoading(false);
                        setDisplayData(null);
                        setToastMessage('Ошибка при загрузке табеля. Пожалуйста, попробуйте позже.');
                        setToastType('error');
                    });
            };


    // Загрузка данных дневных отчетов
    const loadAggregatedReports = () => {
            getAggregatedDailyReports(selectedFactory.value, selectedMonth)
                .then(data => {
                    setAggregatedData(data);
                })
                .catch(error => {
                    console.error("Ошибка при загрузке дневных отчетов:", error);
                    setToastMessage('Ошибка при загрузке дневных отчетов. Пожалуйста, попробуйте позже.');
                    setToastType('error');
                });
        };

    // Сохранение табеля
    const handleSaveTimesheet = () => {
            if (!selectedFactory || !selectedMonth || !displayData) {
                alert("Выберите завод, месяц и загрузите табель.");
                return;
            }

            setLoading(true);
            const timesheetData = {
                factoryId: selectedFactory.value,
                month: selectedMonth,
                data: displayData
            };

            uploadTimesheet(timesheetData)
                .then(() => {
                    setToastMessage('Табель успешно сохранен');
                    setToastType('success');
                    setIsModified(false);
                    setIsLoadedFromDatabase(true);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Ошибка при сохранении табеля:", error);
                    setLoading(false);
                    setToastMessage('Ошибка при сохранении табеля. Пожалуйста, попробуйте позже.');
                    setToastType('error');
                });
        };

    // Отмена изменений - возвращаем состояние таблицы к состоянию из базы
    const handleCancelChanges = () => {
        handleLoadTimesheet();
        setIsModified(false); // Сброс флага изменений
    };

    // Блокировка изменения фильтров
    const isFilterDisabled = isModified; // Если были изменения, блокируем фильтры

    const handleMonthChange = (e) => {
        if (!isFilterDisabled) {
            setSelectedMonth(e.target.value);
        }
    };

    const decrementMonth = () => {
        if (!isFilterDisabled) {
            setSelectedMonth(format(subMonths(new Date(selectedMonth), 1), 'yyyy-MM'));
        }
    };

    const incrementMonth = () => {
        if (!isFilterDisabled) {
            setSelectedMonth(format(addMonths(new Date(selectedMonth), 1), 'yyyy-MM'));
        }
    };

    // Функция для удаления строки
    const handleRemoveRow = (rowIndex) => {
        const updatedData = [...displayData]; // Создаем копию данных
        updatedData.splice(rowIndex, 1); // Удаляем выбранную строку
        setDisplayData(updatedData); // Обновляем состояние с новыми данными
        setIsModified(true); // Помечаем, что таблица была изменена
    };

    // Проверка на изменения данных в таблице
    const handleCellChange = (e, rowIndex, dayIndex) => {
        let newValue = e.target.innerText.trim();
        newValue = newValue === '' ? 0 : parseFloat(newValue);

        if (!isNaN(newValue)) {
            const updatedData = [...displayData];
            updatedData[rowIndex].hours[dayIndex] = newValue;
            setDisplayData(updatedData);
        }

        setIsModified(true); // Изменения в таблице
    };

    // Универсальная функция для отображения данных в виде таблицы
    const renderTable = (data) => {
        // Если нет данных табеля и данных дневных отчетов, показываем сообщение
        if (!data && !aggregatedData) {
            return <div>Данные не найдены или некорректны.</div>;
        }

        const workDayCounts = new Array(31).fill(0); // Инициализация массива для количества сотрудников по дням

        // Если данные табеля есть, подсчитываем по ним количество работников
        if (data) {
            data.forEach((row) => {
                row.hours.forEach((hours, index) => {
                    if (hours > 0) {
                        workDayCounts[index]++;
                    }
                });
            });
        }

        // Функция для вычисления разницы между табелем и дневными отчетами
        const calculateDifference = () => {
            return workDayCounts.map((count, index) => {
                const dailyReportValue = aggregatedData && aggregatedData[index] ? aggregatedData[index] : 0;
                return count - dailyReportValue;
            });
        };

        const difference = aggregatedData ? calculateDifference() : null;

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.target.blur(); // Потеря фокуса при нажатии Enter
            }
        };

        // Выделение текста в ячейке при фокусе
        const handleFocus = (e) => {
            const range = document.createRange();
            range.selectNodeContents(e.target);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        };

        return (
            <div className="excel-table-container">
                <table className="excel-table">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            {[...Array(31).keys()].map(day => (
                                <th key={day + 1}>{day + 1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data && (
                            <tr className="highlight-row">
                                <td>Табель</td>
                                {workDayCounts.map((count, i) => (
                                    <td key={i}>{count}</td>
                                ))}
                            </tr>
                        )}
                        {!data && (
                            <tr className="highlight-row">
                                <td>Табель</td>
                                <td colSpan="32">табель не загружен</td>
                            </tr>
                        )}

                        {aggregatedData && (
                            <tr className="highlight-row">
                                <td>Дневные отчёты</td>
                                {aggregatedData.slice(0, 31).map((data, i) => (
                                    <td key={i}>{data}</td>
                                ))}
                            </tr>
                        )}

                        {data && aggregatedData && difference && (
                            <tr className="highlight-row">
                                <td>Разница</td>
                                {difference.slice(0, 31).map((diff, i) => (
                                    <td key={i} className={diff > 0 ? 'highlight-difference-plus' : (diff < 0 ? 'highlight-difference-minus' : 'highlight-difference')}>{diff}</td>
                                ))}
                            </tr>
                        )}

                        <tr className="collapse-row" onClick={() => setIsEmployeeRowsCollapsed(!isEmployeeRowsCollapsed)}>
                            <td colSpan="32" className="collapse-cell">
                                <span className="collapse-button">
                                    {isEmployeeRowsCollapsed ? "▼" : "▲"}
                                </span>
                            </td>
                        </tr>

                        {!isEmployeeRowsCollapsed && data && data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>
                                    {rowIndex + 1}. {row.name}
                                    <button
                                        className="remove-row-button"
                                        onClick={() => handleRemoveRow(rowIndex)}
                                        title="Удалить строку"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'red',
                                            cursor: 'pointer'
                                        }}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </td>
                                {row.hours.map((hour, dayIndex) => (
                                    <td
                                        key={dayIndex}
                                        contentEditable
                                        suppressContentEditableWarning
                                        className={hour === 0 ? 'faded-zero' : ''}
                                        onFocus={handleFocus}
                                        onBlur={(e) => handleCellChange(e, rowIndex, dayIndex)}
                                        onKeyDown={handleKeyDown}
                                    >
                                        {hour === 0 ? '0' : hour}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const handleUploadNotAllowed = () => {
            if (!selectedFactory && !selectedMonth) {
                setToastMessage('Пожалуйста, выберите завод и месяц перед загрузкой табеля.');
                setToastType('error');
            } else if (!selectedFactory) {
                setToastMessage('Пожалуйста, выберите завод перед загрузкой табеля.');
                setToastType('error');
            } else if (!selectedMonth) {
                setToastMessage('Пожалуйста, выберите месяц перед загрузкой табеля.');
                setToastType('error');
            }
        };

    return (
            <div className="monthly-reports-page">
                <h1 className="monthly-reports-page__title">Табель</h1>
                <div className="monthly-reports-page__controls">
                    <div className="monthly-reports-page__control-group">
                        <button
                            className="monthly-reports-page__arrow-button"
                            onClick={decrementFactory}
                            disabled={isFilterDisabled}
                        >
                            &#9664;
                        </button>
                        <Select
                            id="factory-select"
                            options={factories}
                            value={selectedFactory}
                            onChange={handleFactoryChange}
                            placeholder="Выберите завод"
                            className="monthly-reports-page__select"
                            isDisabled={isFilterDisabled}
                        />
                        <button
                            className="monthly-reports-page__arrow-button"
                            onClick={incrementFactory}
                            disabled={isFilterDisabled}
                        >
                            &#9654;
                        </button>
                    </div>
                    <div className="monthly-reports-page__control-group">
                        <button
                            className="monthly-reports-page__arrow-button"
                            onClick={decrementMonth}
                            disabled={isFilterDisabled}
                        >
                            &#9664;
                        </button>
                        <input
                            type="month"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            placeholder="Выберите месяц"
                            className="monthly-reports-page__month-input"
                            disabled={isFilterDisabled}
                        />
                        <button
                            className="monthly-reports-page__arrow-button"
                            onClick={incrementMonth}
                            disabled={isFilterDisabled}
                        >
                            &#9654;
                        </button>
                    </div>
                    <div className="monthly-reports-page__actions">
                        <button
                            onClick={handleSaveTimesheet}
                            className="monthly-reports-page__button monthly-reports-page__button--save"
                            disabled={!isModified}
                        >
                            Сохранить табель
                        </button>
                        {isModified && (
                            <button
                                onClick={handleCancelChanges}
                                className="monthly-reports-page__button monthly-reports-page__button--cancel"
                            >
                                Отмена
                            </button>
                        )}
                    </div>
                </div>

                <div className="monthly-reports-page__upload">
                    <label className="monthly-reports-page__upload-label">
                        Загрузите табель (xlsx):
                    </label>
                    <ExcelParser
                        onParsedData={handleParsedData}
                        selectedFactory={selectedFactory}
                        selectedMonth={selectedMonth}
                        onUploadNotAllowed={handleUploadNotAllowed}
                        onError={handleParserError} // Передаем функцию для обработки ошибок
                    />
                </div>

                <div className="monthly-reports-page__table-container">
                    {renderTable(displayData)}
                </div>

                {loading && (
                    <div className="monthly-reports-page__loading-overlay">
                        <div className="monthly-reports-page__loader"></div>
                        <p>Загрузка данных...</p>
                    </div>
                )}

                {toastMessage && (
                    <Toast
                        message={toastMessage}
                        type={toastType}
                        onClose={() => setToastMessage(null)}
                    />
                )}
            </div>
        );
};

export default MonthlyReportsPage;