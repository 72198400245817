import React, { useState, useEffect } from 'react';
import { getAllFactories, createFactory, updateFactory, deleteFactory } from '../api';
import './FactoriesPage.css'; // Стили
import Toast from './Toast'; // Компонент уведомлений
import CreateOrEditFactoryModal from './CreateOrEditFactoryModal'; // Импортируем модальное окно

const FactoriesPage = () => {
    const [factories, setFactories] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingFactory, setEditingFactory] = useState(null);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState('');
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        loadFactories();
    }, []);

    const loadFactories = () => {
        getAllFactories().then(response => {
            setFactories(response.data);
        }).catch(error => {
            console.error('Error loading factories', error);
            setToastMessage(error?.response?.data?.error || 'Ошибка загрузки заводов');
            setToastType('error');
        });
    };

    const handleAddFactory = (newFactory) => {
        createFactory(newFactory).then(() => {
            setIsAddModalOpen(false);
            loadFactories();
            setToastMessage('Завод успешно добавлен');
            setToastType('success');
        }).catch(error => {
            setValidationErrors(error?.response?.data?.errors || {});
            setToastMessage(error?.response?.data?.error || 'Ошибка при добавлении завода');
            setToastType('error');
        });
    };

    const handleEditFactory = (updatedFactory) => {
        updateFactory(updatedFactory.id, updatedFactory).then(() => {
            setIsEditModalOpen(false);
            setEditingFactory(null);
            loadFactories();
            setToastMessage('Завод успешно обновлён');
            setToastType('success');
        }).catch(error => {
            setValidationErrors(error?.response?.data?.errors || {});
            setToastMessage(error?.response?.data?.error || 'Ошибка при обновлении завода');
            setToastType('error');
        });
    };

    const handleDeleteFactory = (factoryId) => {
        if (window.confirm("Вы уверены, что хотите удалить этот завод?")) {
            deleteFactory(factoryId).then(() => {
                loadFactories();
                setToastMessage('Завод успешно удалён');
                setToastType('success');
            }).catch(error => {
                setToastMessage(error?.response?.data?.error || 'Ошибка при удалении завода');
                setToastType('error');
            });
        }
    };

    return (
        <div className="factories-page__container">
            <button onClick={() => {
                setIsAddModalOpen(true);
                setValidationErrors({});
            }} className="factories-page__btn-create-factory">
                Добавить завод
            </button>

            <div className="factories-page__factories-list">
                <h3>Список заводов</h3>
                <table className="factories-page__factory-table">
                    <thead>
                        <tr>
                            <th>Название завода</th>
                            <th>Курирующий мастер</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {factories.map(factory => (
                            <tr key={factory.id}>
                                <td><span key={factory.id}>
                                    {factory.deleted ? <s>{factory.name}</s> : factory.name}
                                </span></td>
                                <td>{factory.master ? factory.master.fio : '-'}</td>
                                <td>
                                    <button onClick={() => {
                                        setEditingFactory(factory);
                                        setIsEditModalOpen(true);
                                        setValidationErrors({});
                                    }}>Редактировать</button>
                                    <button onClick={() => handleDeleteFactory(factory.id)} className="factories-page__btn-delete">
                                        Удалить
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setToastMessage(null)}
                />
            )}

            {/* Модальное окно для добавления завода */}
            {isAddModalOpen && (
                <CreateOrEditFactoryModal
                    onSave={handleAddFactory}
                    onClose={() => setIsAddModalOpen(false)}
                    validationErrors={validationErrors}
                />
            )}

            {/* Модальное окно для редактирования завода */}
            {isEditModalOpen && editingFactory && (
                <CreateOrEditFactoryModal
                    factory={editingFactory}
                    onSave={handleEditFactory}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        setEditingFactory(null);
                    }}
                    validationErrors={validationErrors}
                />
            )}
        </div>
    );
};

export default FactoriesPage;
