import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkManagerAccess } from '../api';
import './ManagerLogin.css';

const ManagerLogin = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        checkManagerAccess(code)
            .then((response) => {
                if (response.data) {
                    navigate('/manager/daily-reports');
                } else {
                    setError('Неправильный код доступа');
                }
            })
            .catch((error) => {
                console.error('Error checking manager access', error);
                setError('Произошла ошибка при проверке доступа');
            });
    };

    return (
        <div className="panel-login">
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <label className="label-login">Введите код доступа:</label>
                    <input
                        name="login"
                        value="Менеджер"
                        className="input-login"
                        disabled={true}
                        />
                    <input
                        type="password"
                        value={code}
                        className="input-login"
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <button type="submit" className="submit-button-login">
                        Войти
                    </button>
                </form>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default ManagerLogin;
