import React, { useEffect } from 'react';
import './Toast.css';

const Toast = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000); // Убираем плашку через 3 секунды

        return () => clearTimeout(timer); // Очищаем таймер при размонтировании
    }, [onClose]);

    return (
         <div className={`toast toast--${type}`}>
                    <span>{message}</span>
                    <button onClick={onClose} className="toast__close-button">&times;</button>
                </div>
    );
};

export default Toast;
